import { combineReducers } from 'redux';
import createReducer from '../../utils/createReducer';
import * as types from './types';

const dataReducer = createReducer({})({
  [ types.LOAD_COMPLETED ]: (state, action) => ({ ...state, [`${action.meta.tvchannelId}-${action.meta.date}`]: { list: action.payload, timestamp: Date.now() } })
});

export default combineReducers({
  data: dataReducer
});
