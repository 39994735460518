import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import TvIcon from "@material-ui/icons/Tv";
import SecurityIcon from "@material-ui/icons/Security";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#aaa",
    color: "#111",
    overflow: "hidden",
    transition: "width 0.2s",
  },
  icon: {
    color: "#111",
  },
}));

function Sidebar({ history, tvchannelId, onLogout }) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(
    history.location.pathname
  );
  const [hidden, setHidden] = React.useState(true);

  function handleListItemClick(event, path) {
    setSelectedIndex(path);
    history.push(path);
  }

  return (
    <div
      className={classes.root}
      style={{ width: hidden ? 60 : 280 }}
      onMouseEnter={(event) => setHidden(false)}
      onMouseLeave={(event) => setHidden(true)}
    >
      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          button
          selected={selectedIndex === "/"}
          onClick={(event) => handleListItemClick(event, "/")}
          style={{ height: 40 }}
        >
          <ListItemIcon className={classes.icon}>
            <DashboardIcon />
          </ListItemIcon>
          <span style={{ whiteSpace: "nowrap" }}>Dashboard</span>
        </ListItem>
        <ListItem
          button
          selected={
            selectedIndex === `/tvchannels/${tvchannelId}/hourly-recordings`
          }
          onClick={(event) =>
            handleListItemClick(
              event,
              `/tvchannels/${tvchannelId}/hourly-recordings`
            )
          }
          style={{ height: 40 }}
        >
          <ListItemIcon className={classes.icon}>
            <AccessTimeIcon />
          </ListItemIcon>
          <span style={{ whiteSpace: "nowrap" }}>Hourly-Recordings</span>
        </ListItem>
        <ListItem
          button
          selected={
            selectedIndex === `/tvchannels/${tvchannelId}/product-presentations`
          }
          onClick={(event) =>
            handleListItemClick(
              event,
              `/tvchannels/${tvchannelId}/product-presentations`
            )
          }
          style={{ height: 40 }}
        >
          <ListItemIcon className={classes.icon}>
            <SlideshowIcon />
          </ListItemIcon>
          <span style={{ whiteSpace: "nowrap" }}>Product Presentations</span>
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === `/tvchannels/${tvchannelId}/configs`}
          onClick={(event) =>
            handleListItemClick(event, `/tvchannels/${tvchannelId}/configs`)
          }
          style={{ height: 40 }}
        >
          <ListItemIcon className={classes.icon}>
            <TvIcon />
          </ListItemIcon>
          <span style={{ whiteSpace: "nowrap" }}>TV Channels</span>
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === "/configs/private-ips"}
          onClick={(event) =>
            handleListItemClick(event, "/configs/private-ips")
          }
          style={{ height: 40 }}
        >
          <ListItemIcon className={classes.icon}>
            <SecurityIcon />
          </ListItemIcon>
          <span style={{ whiteSpace: "nowrap" }}>Private API access</span>
        </ListItem>
        <ListItem button onClick={onLogout} style={{ height: 40 }}>
          <ListItemIcon className={classes.icon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <span style={{ whiteSpace: "nowrap" }}>Logout</span>
        </ListItem>
      </List>
    </div>
  );
}

export default withRouter(Sidebar);
