import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router";
import { format } from "date-fns";

import Details from "../../components/Details";

import {
  hourlyRecordingsOperations,
  hourlyRecordingsSelectors,
} from "../../state/ducks/hourly-recordings";
import { sessionOperations } from "../../state/ducks/session";

const styles = (theme) => ({
  root: {
    height: "100vh",
    overflow: "hidden",
  },
  header: {
    height: 58,
  },
  body: {
    height: "calc( 100vh - 58px)",
    overflow: "auto",
    fontSize: 10,
    fontFamily: "courier",
  },
  pre: {
    margin: 5,
    padding: 5,
    border: "1px solid black",
    background: "#eee",
  },
});

class HourlyRecording extends React.Component {
  componentDidMount() {
    const { match, setDate, date, data } = this.props;
    const d = format(
      new Date(parseInt(match.params.start_datetime) * 1000),
      "yyyy-MM-dd"
    );
    if (d !== date) {
      setDate(d);
    } else if (!data || Date.now() - data.timestamp > 5000) {
      this.handleLoad();
    }
  }

  componentDidUpdate(props) {
    const { data } = this.props;
    if (!data || Date.now() - data.timestamp > 5000) {
      this.handleLoad();
    }
  }

  handleLoad() {
    const { tvchannelId, date, load } = this.props;
    return load(tvchannelId, date);
  }

  handleBack() {
    const { history, tvchannelId } = this.props;
    history.push(`/tvchannels/${tvchannelId}/hourly-recordings`);
  }

  render() {
    let { classes, data } = this.props;
    data = data && data[0];
    return (
      <div className={classes.root}>
        {data && [
          <div key="header" className={classes.header}>
            <Details
              channel={data.tvchannel_id}
              start={data.start_datetime}
              end={data.end_datetime}
              status={data.status}
              onRetrigger={() => console.log("retrigger")}
              onBack={this.handleBack.bind(this)}
            />
          </div>,
          <div key="body" className={classes.body}>
            <pre className={classes.pre}>
              <code>{JSON.stringify(data, "", 2)}</code>
            </pre>
          </div>,
        ]}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: hourlyRecordingsSelectors.hourlyRecording(
    state.hourlyRecordings,
    state.session.tvchannelId,
    state.session.date,
    parseInt(props.match.params.start_datetime)
  ),
  tvchannelId: state.session.tvchannelId,
  date: state.session.date,
});

const mapDispatchToProps = {
  load: (tvchannelId, date) =>
    hourlyRecordingsOperations.load({ tvchannelId, date }),
  setDate: (date) => sessionOperations.setDate(date),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(HourlyRecording)));
