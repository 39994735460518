import axios from "axios";
import _ from "lodash";
import { sessionOperations } from "../ducks/session";
import uuid from "uuidv4";

let auth = null;

const apiService = (store) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  return (next) => (action) => {
    const { meta } = action;
    const id = uuid();
    if (meta) {
      const params = {
        url: `${baseUrl}${meta.path}`,
        method: meta.method || "get",
        data: meta.data,
        headers: {
          Authorization: `${auth?.accessToken.tokenType} ${auth?.accessToken.accessToken}`,
        },
      };
      next(sessionOperations.apiStarted({ id, ...params }));
      return axios(params)
        .then((res) => {
          next(sessionOperations.apiSucceeded({ id }));
          next({
            type: `${action.type}_COMPLETED`,
            payload: res.data,
            meta: meta,
          });
          return res;
        })
        .catch((err) => {
          if (_.get(err, "response.status")) {
            next(sessionOperations.logout);
          }
          next(sessionOperations.apiFailed({ id }));
          next({
            type: `${action.type}_FAILED`,
            payload: err,
            meta: meta,
          });
          return Promise.reject(err);
        });
    } else {
      return next(action);
    }
  };
};

function setAuthState(oktaAuth) {
  auth = oktaAuth;
}

export default apiService;
export { setAuthState };
