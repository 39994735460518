export const LOGIN = 'session/LOGIN';
export const LOGIN_COMPLETED = 'session/LOGIN_COMPLETED';
export const LOGIN_FAILED = 'session/LOGIN_FAILED';
export const LOGOUT = 'session/LOGOUT';
export const INITIALIZE = 'session/INITIALIZE_SESSION';

export const PASSWORD_RESET = 'session/PASSWORD_RESET';
export const PASSWORD_RESET_COMPLETED = 'session/PASSWORD_RESET_COMPLETED';
export const PASSWORD_RESET_FAILED = 'session/PASSWORD_RESET_FAILED';

export const PASSWORD_CHANGE = 'session/PASSWORD_CHANGE';
export const PASSWORD_CHANGE_COMPLETED = 'session/PASSWORD_CHANGE_COMPLETED';
export const PASSWORD_CHANGE_FAILED = 'session/PASSWORD_CHANGE_FAILED';

export const SET_TVCHANNEL = 'session/SET_TVCHANNEL';
export const SET_DATE = 'session/SET_DATE';
export const SET_STATUS_FILTER = 'session/SET_STATUS_FILTER';
export const SET_SKU_FILTER = 'session/SET_SKU';

export const API_STARTED = 'session/API_STARTED';
export const API_SUCCEEDED = 'session/API_SUCCEEDED';
export const API_FAILED = 'session/API_FAILED';
