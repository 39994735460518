import reducer from './reducers';

import * as cleanfeedsOperations from './operations';
import * as cleanfeedsSelectors from './selectors';

export {
  cleanfeedsOperations,
  cleanfeedsSelectors
};

export default reducer;
