import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { format } from 'date-fns';

import { statusOperations } from '../../state/ducks/status';
import { hourlyRecordingsOperations } from '../../state/ducks/hourly-recordings';
import { cleanfeedsOperations } from '../../state/ducks/cleanfeeds';
import { sessionOperations } from '../../state/ducks/session';

import StatusList from '../../components/StatusList';
import IconButton from '@material-ui/core/IconButton';

import CachedIcon from '@material-ui/icons/Cached';

const styles = (theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden'
  },
  header: {
    height: 38,
    backgroundColor: '#ddd',
    padding: 10,
    fontSize: 12,
    textAlign: 'right'
  },
  body: {
    height: 'calc( 100vh - 58px)',
    overflow: 'auto'
  }
});

class Status extends React.Component {
  componentDidMount () {
    const { data } = this.props;
    if (!data || (Date.now() - data.timestamp) > 5000) {
      this.handleLoad();
    }
  }

  handleLoad () {
    const { load } = this.props;
    return load();
  }

  handleRedirect (type, tvchannelId, startDatetime) {
    this.props.setChannel(tvchannelId);
    this.props.history.push(`/tvchannels/${tvchannelId}/${type}/${startDatetime}`);
  }

  handleHourlyRecordingRetrigger (tvchannelId, startDatetime, server) {
    this.props.retriggerHourlyRecordings({ tvchannelId, startDatetime, server })
      .then((res) => {
        this.handleLoad();
      });
  }

  handleCleanfeedRetrigger (tvchannelId, startDatetime, server) {
    this.props.retriggerCleanfeed({ tvchannelId, startDatetime, server })
      .then((res) => {
        this.handleLoad();
      });
  }

  render () {
    let { classes, data } = this.props;
    return (
      <div className={classes.root}>
        { data &&
          [
            <div key='header' className={classes.header}>
              <i>status as of: </i>
              <span>{format(data.timestamp, 'yyyy-MM-dd HH:mm:ss')}</span>
              <IconButton color='primary' className={classes.refresh} aria-label='refresh' onClick={this.handleLoad.bind(this)}>
                <CachedIcon />
              </IconButton>
            </div>,
            <div key='body' className={classes.body}>
              <StatusList
                label='Failed Cleanfeeds'
                type='alert'
                list={data.data.FAILED_CLEANFEEDS}
                onClick={this.handleRedirect.bind(this, 'product-presentations')}
                onRetrigger={this.handleCleanfeedRetrigger.bind(this)}
              />
              <StatusList
                label='Failed Hourly Recordings'
                type='alert'
                list={data.data.FAILED_HOURLY_RECORDINGS}
                onClick={this.handleRedirect.bind(this, 'hourly-recordings')}
                onRetrigger={this.handleHourlyRecordingRetrigger.bind(this)}
              />
              <StatusList
                label='Running Cleanfeeds'
                type='info'
                list={data.data.RUNNING_CLEANFEEDS}
                onClick={this.handleRedirect.bind(this, 'product-presentations')}
              />
              <StatusList
                label='Running Hourly Recordings'
                type='info'
                list={data.data.RUNNING_HOURLY_RECORDINGS}
                onClick={this.handleRedirect.bind(this, 'hourly-recordings')}
              />
              {/* {JSON.stringify(data.data)} */}
            </div>
          ]
        }
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: state.status.data
});

const mapDispatchToProps = {
  load: () => statusOperations.load(),
  retriggerHourlyRecordings: ({ tvchannelId, startDatetime, server }) => hourlyRecordingsOperations.retrigger({ tvchannelId, startDatetime, server }),
  retriggerCleanfeed: ({ tvchannelId, startDatetime, server }) => cleanfeedsOperations.retrigger({ tvchannelId, startDatetime, server }),
  setChannel: (channel) => sessionOperations.setTvChannel(channel)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Status)));
