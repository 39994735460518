import React from "react";
import { connect } from "react-redux";

import { ThemeProvider, withStyles } from "@material-ui/styles";

import Hidden from "@material-ui/core/Hidden";
import { Route } from "react-router-dom";

import Sidebar from "../../components/Sidebar";

import { Status } from "../Status";
import { Cleanfeeds } from "../Cleanfeeds";
import { Cleanfeed } from "../Cleanfeed";
import { HourlyRecordings } from "../HourlyRecordings";
import { HourlyRecording } from "../HourlyRecording";
import { Channel } from "../Channel";
import { PrivateIP } from "../PrivateIP";
import { withOktaAuth } from "@okta/okta-react";
import { setAuthState } from "../../state/middlewares/apiService";

import theme from "./theme";

import { init } from "../../state/ducks/session/operations";

const styles = (theme) => ({
  left: {
    float: "left",
  },
  "left:hover": {
    width: 280,
  },
  right: {
    height: "100vh",
    width: "100%",
    verticalAlign: "top",
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  async componentDidMount() {
    this.props.init();
  }

  async logout() {
    console.log("logging out...");
    await this.props.oktaAuth.signOut();
  }

  render() {
    const { classes, tvchannelId } = this.props;
    if (
      !this.props.oktaAuth.isLoginRedirect() &&
      !this.props.authState?.isAuthenticated
    ) {
      this.props.oktaAuth.signInWithRedirect();
    } else {
      setAuthState(this.props.authState);
    }

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          {this.props.authState?.isAuthenticated && (
            <Hidden mdDown>
              <div className={classes.left}>
                <Sidebar tvchannelId={tvchannelId} onLogout={this.logout} />
              </div>
            </Hidden>
          )}
          <Route exact path="/" component={Status} />
          <Route
            exact
            path="/tvchannels/:tvchannel_id/hourly-recordings"
            component={HourlyRecordings}
          />
          <Route
            path="/tvchannels/:tvchannel_id/hourly-recordings/:start_datetime"
            component={HourlyRecording}
          />
          <Route
            exact
            path="/tvchannels/:tvchannel_id/product-presentations"
            component={Cleanfeeds}
          />
          <Route
            path="/tvchannels/:tvchannel_id/product-presentations/:start_datetime"
            component={Cleanfeed}
          />
          <Route
            exact
            path="/tvchannels/:tvchannel_id/configs"
            component={Channel}
          />
          <Route exact path="/configs/private-ips" component={PrivateIP} />
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  // isAuthenticated: state.session.isAuthenticated,
  tvchannelId: state.session.tvchannelId,
  user: state.session.user,
});

const mapDispatchToProps = {
  init,
  // // login,
  // // logout,
  // passwordChange,
  // passwordReset,
};

export default withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))
);
