import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router";

import HourlyRecordingItem from "../../components/HourlyRecordingItem";
import Controller from "../../components/Controller";

import {
  hourlyRecordingsOperations,
  hourlyRecordingsSelectors,
} from "../../state/ducks/hourly-recordings";
import { sessionOperations } from "../../state/ducks/session";

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    height: 120,
    overflow: "hidden",
  },
  headers: {
    borderBottom: "1px solid gray",
    display: "inline-block",
    marginLeft: 5,
    paddingLeft: 18,
    paddingBottom: 5,
    marginTop: 24,
  },
  list: {
    height: "calc( 100vh - 120px)",
    overflow: "auto",
    paddingLeft: 5,
  },
});

class HourlyRecordings extends React.Component {
  componentDidMount() {
    const { data } = this.props;
    if (!data || Date.now() - data.timestamp > 1000 * 60 * 60) {
      this.handleLoad();
    }
  }

  componentDidUpdate(props) {
    const { data } = this.props;
    if (!data || Date.now() - data.timestamp > 1000 * 60 * 60) {
      this.handleLoad();
    }
  }

  handleLoad() {
    const { tvchannelId, date, load } = this.props;
    return load(tvchannelId, date);
  }

  handleItemDetails(item) {
    const { history, tvchannelId } = this.props;
    history.push(
      `/tvchannels/${tvchannelId}/hourly-recordings/${item.start_datetime}`
    );
  }

  handleRetrigger(tvchannelId, startDatetime, server) {
    this.props.retrigger({ tvchannelId, startDatetime, server }).then((res) => {
      this.handleLoad();
    });
  }

  handleDelete(tvchannelId, startDatetime) {
    this.props.remove({ tvchannelId, startDatetime }).then((res) => {
      this.handleLoad();
    });
  }

  render() {
    const {
      classes,
      tvchannelId,
      date,
      data,
      statusFilter,
      skuFilter,
      setDate,
      setChannel,
      setStatusFilter,
      setSkuFilter,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <Controller
            date={date}
            channel={tvchannelId}
            statusFilter={statusFilter}
            skuFilter={skuFilter}
            dataAsOf={data && new Date(data.timestamp)?.toLocaleString()}
            count={data && data.list && data.list.length}
            onDateChange={setDate}
            onChannelChange={setChannel}
            onStatusChange={setStatusFilter}
            onSkuChange={setSkuFilter}
            onRefresh={this.handleLoad.bind(this)}
          />
          <div className={classes.headers}>
            <label style={{ width: 200, display: "inline-block" }}>
              Time (from - to)
            </label>
            <label style={{ width: 195, display: "inline-block" }}>
              Poster
            </label>
            <label style={{ width: 90, display: "inline-block" }}>Server</label>
            <label style={{ width: 150, display: "inline-block" }}>
              Status
            </label>
            <label style={{ width: 350, display: "inline-block" }}>
              Actions
            </label>
          </div>
        </div>
        <div className={classes.list}>
          {data &&
            data.list.map((i) => (
              <HourlyRecordingItem
                key={i.start_datetime}
                data={i}
                onItemDetails={this.handleItemDetails.bind(this)}
                onRetrigger={this.handleRetrigger.bind(this)}
                onDelete={this.handleDelete.bind(this)}
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: hourlyRecordingsSelectors.currentHourlyRecordings(state),
  tvchannelId: state.session.tvchannelId,
  date: state.session.date,
  statusFilter: state.session.statusFilter,
  skuFilter: state.session.skuFilter,
});

const mapDispatchToProps = {
  load: (tvchannelId, date) =>
    hourlyRecordingsOperations.load({ tvchannelId, date }),
  setDate: (date) => sessionOperations.setDate(date),
  setChannel: (channel) => sessionOperations.setTvChannel(channel),
  setStatusFilter: (status) => sessionOperations.setStatusFilter(status),
  setSkuFilter: (status) => sessionOperations.setSkuFilter(status),
  retrigger: ({ tvchannelId, startDatetime, server }) =>
    hourlyRecordingsOperations.retrigger({
      tvchannelId,
      startDatetime,
      server,
    }),
  remove: ({ tvchannelId, startDatetime }) =>
    hourlyRecordingsOperations.remove({ tvchannelId, startDatetime }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(HourlyRecordings)));
