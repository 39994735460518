import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

export default function ButtonOptions ({ options, actions }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClick = (event) => {
    event.stopPropagation();
    actions[0]();
  };

  const handleMenuItemClick = (event, index) => {
    event.stopPropagation();
    setOpen(false);
    actions[index]();
  };

  const handleToggle = (event) => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ display: 'inline-block', marginRight: 10 }}>
      <ButtonGroup variant='outlined' color='secondary' size='small' ref={anchorRef} aria-label='split button'>
        <Button onClick={handleClick}>{options[0]}</Button>
        <Button
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper style={{ zIndex: 1000 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper id='menu-list-grow'>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={event => { event.stopPropagation(); handleMenuItemClick(event, index); }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
