import _ from 'lodash';
import 'date-fns';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';

import TVChannel from '../../components/TVChannel';

import { channelOperations } from '../../state/ducks/channel';
import { sessionOperations } from '../../state/ducks/session';

const styles = (theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden'
  },
  header: {
    height: 58,
    backgroundColor: '#ddd'
  },
  save: {
    float: 'right',
    margin: 10
  },
  tvchannel: {
    display: 'inline-block',
    width: 200,
    padding: 5
  },
  body: {
    height: 'calc( 100vh - 58px)',
    overflow: 'auto'
  },
  paper: {
    padding: 10
  },
  textField: {
    display: 'block',
    width: 600
  },
  numberField: {
    display: 'block',
    width: 145
  },
  hours: {
    width: 200
  },
  detail: {
    fontSize: 9,
    marginTop: 30,
    borderTop: '1px solid #aaa'
  },
  detailheader: {
    fontSize: 12
  }
});

class Channel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      changed: false,
      hours: [],
      from: null,
      to: null
    };
  }

  componentDidMount () {
    this.handleLoad();
  }

  componentDidUpdate (props) {
    if (props.data !== this.props.data) {
      this.setState(state => ({
        ...state,
        posterUrl: _.get(this.props, 'data.livestream.urls[0].poster'),
        posterType: _.get(this.props, 'data.livestream.urls[0].screenType'),
        sourceUrl: _.get(this.props, 'data.livestream.urls[0].sources[0].src'),
        sourceType: _.get(this.props, 'data.livestream.urls[0].sources[0].type'),
        hours: _.get(this.props, 'data.hours', []),
        cleanfeedsMinLength: _.get(this.props, 'data.cleanfeeds.minLength', 60),
        changed: false
      }));
    }
    if (props.tvchannelId !== this.props.tvchannelId) {
      this.handleLoad();
    }
  }

  handleLoad () {
    const { load, tvchannelId } = this.props;
    return load(tvchannelId);
  }

  handleSave () {
    const { save, tvchannelId } = this.props;
    const data = {
      livestream: {
        urls: [
          {
            sources: [
              {
                type: this.state.sourceType,
                src: this.state.sourceUrl
              }
            ],
            screenType: this.state.posterType,
            poster: this.state.posterUrl
          }
        ]
      },
      hours: this.state.hours,
      cleanfeeds: {
        minLength: parseInt(this.state.cleanfeedsMinLength) || 0
      }
    };
    save(tvchannelId, data);
  }

  handleChannelChange (channel) {
    const { setChannel, history } = this.props;
    history.push(`/tvchannels/${channel}/configs`);
    setChannel(channel);
  }

  handleChange (key, event) {
    const value = event.target.value;
    this.setState(state => ({
      [key]: value,
      changed: true
    }));
  }

  handleTimeChange (key, time) {
    this.setState(state => ({
      [key]: time
    }));
  }

  handleAddTime () {
    this.setState(state => ({
      ...state,
      hours: [...state.hours, { from: state.from.toString().slice(16, 21), to: state.to.toString().slice(16, 21) }],
      from: null,
      to: null,
      changed: true
    }));
  }

  handleRemoveTime (from, to) {
    this.setState(state => ({
      ...state,
      hours: state.hours.filter(i => i.from !== from && i.to !== to),
      changed: true
    }));
  }

  render () {
    let { classes, tvchannelId, data } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Paper className={classes.root}>
          <div className={classes.header}>
            <Button variant='contained' color='primary' disabled={!this.state.changed} className={classes.save} onClick={this.handleSave.bind(this)}>Save</Button>
            <div className={classes.tvchannel}>
              <TVChannel channel={tvchannelId} onChange={this.handleChannelChange.bind(this)} />
            </div>
          </div>
          <div className={classes.body}>
            {this.state.posterUrl &&
              <div className={classes.paper}>
                <h3>Livestream:</h3>
                <TextField
                  label='Poster URL'
                  className={classes.textField}
                  value={this.state.posterUrl}
                  onChange={this.handleChange.bind(this, 'posterUrl')}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label='Poster type'
                  className={classes.textField}
                  value={this.state.posterType}
                  onChange={this.handleChange.bind(this, 'posterType')}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label='Source URL'
                  className={classes.textField}
                  value={this.state.sourceUrl}
                  onChange={this.handleChange.bind(this, 'sourceUrl')}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label='Source type'
                  className={classes.textField}
                  value={this.state.sourceType}
                  onChange={this.handleChange.bind(this, 'sourceType')}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />

                <h3>Cleanfeeds:</h3>

                <FormControl className={clsx(classes.margin, classes.withoutLabel, classes.textField)}>
                  <FormHelperText id='standard-weight-helper-text'>Minimal Length</FormHelperText>
                  <Input
                    id='standard-adornment-weight'
                    type='number'
                    value={this.state.cleanfeedsMinLength}
                    onChange={this.handleChange.bind(this, 'cleanfeedsMinLength')}
                    endAdornment={<InputAdornment position='end'>Seconds</InputAdornment>}
                    aria-describedby='standard-weight-helper-text'
                    inputProps={{
                      'aria-label': 'weight'
                    }}
                  />
                </FormControl>

                <h3 style={{ paddingTop: 20 }}>Business hours:</h3>
                { !this.state.hours.length &&
                  <div>--- no business hours ---</div>
                }
                <List className={classes.hours} aria-label='business hours'>
                  {this.state.hours.map((item, index) =>
                    <ListItem key={index + item.from + item.to}>
                      <ListItemText>
                        {item.from} - {item.to}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton edge='end' aria-label='delete' onClick={this.handleRemoveTime.bind(this, item.from, item.to)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </List>
                <div>
                  <KeyboardTimePicker
                    style={{ width: 100 }}
                    margin='normal'
                    ampm={false}
                    id='time-picker-from'
                    label='From'
                    value={this.state.from}
                    onChange={this.handleTimeChange.bind(this, 'from')}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                  <KeyboardTimePicker
                    style={{ width: 100, marginLeft: 10 }}
                    margin='normal'
                    ampm={false}
                    id='time-picker-to'
                    label='To'
                    value={this.state.to}
                    onChange={this.handleTimeChange.bind(this, 'to')}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                  <Button style={{ verticalAlign: 'bottom', marginBottom: 5, marginLeft: 10 }} variant='contained' color='primary' disabled={!this.state.from || !this.state.to} onClick={this.handleAddTime.bind(this)}>Add</Button>
                </div>
                <div className={classes.detail}>
                  <div className={classes.detailheader}>Record details:</div>
                  <div><label style={{ width: 50, display: 'inline-block' }}>created:</label>{ data.createdAt}</div>
                  <div><label style={{ width: 50, display: 'inline-block' }}>modified:</label>{ data.modifiedAt}</div>
                  <div><label style={{ width: 50, display: 'inline-block' }}>version:</label>{ data.version}</div>
                </div>
              </div>
            }
          </div>
        </Paper>
      </MuiPickersUtilsProvider>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: state.channel.data,
  tvchannelId: state.session.tvchannelId
});

const mapDispatchToProps = {
  load: (tvchannelId) => channelOperations.load(tvchannelId),
  save: (tvchannelId, data) => channelOperations.save(tvchannelId, data),
  setChannel: (tvchannelId) => sessionOperations.setTvChannel(tvchannelId)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Channel)));
