import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { privateipOperations } from '../../state/ducks/privateip';

const styles = (theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden'
  },
  header: {
    height: 28,
    backgroundColor: '#ddd',
    padding: 15
  },
  save: {
    float: 'right',
    margin: 10
  },
  body: {
    height: 'calc( 100vh - 58px)',
    overflow: 'auto'
  },
  ips: {
    width: 250
  },
  detail: {
    fontSize: 9,
    marginTop: 30,
    borderTop: '1px solid #aaa',
    color: '#444'
  },
  detailheader: {
    fontSize: 12
  }
});

class Channel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      changed: false,
      ip: ''
    };
  }

  componentDidMount () {
    this.handleLoad();
  }

  componentDidUpdate (props) {
    if (props.data !== this.props.data) {
      this.setState(state => ({
        ...state,
        ips: _.get(this.props, 'ips', []),
        ipOk: false,
        ip: ''
      }));
    }
  }

  handleLoad () {
    const { load } = this.props;
    return load();
  }

  handleAdd () {
    const { add } = this.props;
    add(this.state.ip);
  }

  handleRemove (ip) {
    const { remove } = this.props;
    remove(ip);
  }

  handleChange (event) {
    const ip = event.target.value;
    this.setState(state => ({
      ...state,
      ip,
      ipOk: ip.match(/^(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(((\/([4-9]|[12][0-9]|3[0-2]))?)|\s?-\s?((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))))(,\s?|$))+/)
    }));
  }

  render () {
    let { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          Private IP whitelist
        </div>
        <div className={classes.body}>
          { data &&
          <div style={{ padding: 15 }}>
            <List className={classes.ips} aria-label='business hours'>
              {(data.ips || []).map((ip, index) =>
                <ListItem key={index + ip}>
                  <ListItemText>
                    {ip}
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton edge='end' aria-label='delete' onClick={this.handleRemove.bind(this, ip)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
            <div>
              <TextField
                id='ip'
                label='IP'
                type='search'
                value={this.state.ip}
                margin='dense'
                onChange={this.handleChange.bind(this)}
              />

              <Button style={{ verticalAlign: 'bottom', marginBottom: 5, marginLeft: 10 }} variant='contained' color='primary' disabled={!this.state.ipOk} onClick={this.handleAdd.bind(this)}>Add</Button>
            </div>
            <div className={classes.detail}>
              <div className={classes.detailheader}>Record details:</div>
              <div><label style={{ width: 50, display: 'inline-block' }}>created:</label>{ data.createdAt}</div>
              <div><label style={{ width: 50, display: 'inline-block' }}>modified:</label>{ data.modifiedAt}</div>
              <div><label style={{ width: 50, display: 'inline-block' }}>version:</label>{ data.version}</div>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: state.privateip.data
});

const mapDispatchToProps = {
  load: () => privateipOperations.load(),
  add: (ip) => privateipOperations.add(ip),
  remove: (ip) => privateipOperations.remove(ip)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Channel)));
