import React from 'react';
import Button from '@material-ui/core/Button';

export default function ButtonAction ({ label = 're-trigger', action }) {
  const handleClick = (event) => {
    event.stopPropagation();
    action();
  };

  return (
    <div style={{ display: 'inline-block', marginRight: 10 }}>
      <Button variant='outlined' color='secondary' onClick={handleClick}>
        {label}
      </Button>
    </div>
  );
}
