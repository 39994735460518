import {
  login,
  passwordReset,
  passwordChange,
  setTvChannel,
  setDate,
  setStatusFilter,
  setSkuFilter,
  apiStarted,
  apiSucceeded,
  apiFailed,
} from "./actions";
import * as cookies from "es-cookie";
import { format } from "date-fns";

const initOperation = () => {
  return (dispatch) => {
    // const token = cookies.get('token');
    let tvchannelId = window.location.href.match(/\/tvchannels\/([A-Z0-9]+)\//);
    tvchannelId = tvchannelId ? tvchannelId[1] : "HSE24";
    // dispatch(init({ token }));
    dispatch(setTvChannel(tvchannelId));
  };
};

const loginOperation = (email, password) => {
  return (dispatch) => {
    return dispatch(
      login({
        meta: {
          path: "/api/v1/login",
          method: "post",
          data: {
            email,
            password,
          },
        },
      })
    ).then((res) => {
      console.log("cookies: ", res.data.token);
      if (res.data.token) {
        cookies.set("token", res.data.token, { expires: 7 });
      }
    });
  };
};

const logoutOperation = () => {};

const setTvChannelOperation = (tvchannelId) => {
  window.history.replaceState(
    {},
    "HSE24",
    window.location.href.replace(
      /\/tvchannels\/([A-Z0-9]+)\//,
      `/tvchannels/${tvchannelId}/`
    )
  );
  return setTvChannel(tvchannelId);
};

const setDateOperation = (date) => {
  if (typeof date === "number") {
    date = format(new Date(date * 1000), "yyyy-MM-dd");
  }
  return setDate(date);
};

const passwordResetOperation = (email) => {
  return (dispatch) => {
    return dispatch(
      passwordReset({
        meta: {
          path: "/api/v1/password-reset",
          method: "post",
          data: {
            email,
            url: window.location.origin,
          },
        },
      })
    );
  };
};

const passwordChangeOperation = ({ email, password, recoveryToken }) => {
  return (dispatch) => {
    return dispatch(
      passwordChange({
        meta: {
          path: "/api/v1/password-change",
          method: "post",
          data: {
            email,
            newPassword: password,
            recoveryToken,
          },
        },
      })
    ).then((res) => {
      console.log("cookies: ", res.data.token);
      if (res.data.token) {
        cookies.set("token", res.data.token, { expires: 7 });
        window.location.href = "/";
      }
    });
  };
};

export {
  loginOperation as login,
  logoutOperation as logout,
  passwordChangeOperation as passwordChange,
  passwordResetOperation as passwordReset,
  initOperation as init,
  setTvChannelOperation as setTvChannel,
  setDateOperation as setDate,
  setStatusFilter,
  setSkuFilter,
  apiStarted,
  apiSucceeded,
  apiFailed,
};
