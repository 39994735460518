import React from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ddd',
    padding: 5
  },
  item: {
    fontSize: 24,
    margin: '0px 20px',
    verticalAlign: 'middle',
    display: 'inline-block'
  }
}));

export default function Details ({ channel, start, end, status, onRetrigger, onBack, onRefresh }) {
  const classes = useStyles();
  const startDatetime = new Date(start * 1000);
  const endDatetime = end && new Date(end * 1000);
  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={onBack}>
        <ArrowBackIcon />
      </IconButton>
      <div className={classes.item}>{channel}</div>
      <div className={classes.item}>{format(startDatetime, 'yyyy-MM-dd')}</div>
      <div className={classes.item}>{format(startDatetime, 'HH:mm:ss')} - {endDatetime ? format(endDatetime, 'HH:mm:ss') : ''}</div>
      <div className={classes.item}>{status}</div>
    </div>
  );
}
