import { createSelector } from 'reselect';

const statusFilter = (status) => {
  switch (status) {
    case 1:
      return (status) => status === 'running' || status === 'failed';
    case 2:
      return (status) => status === 'failed';
    default:
      return () => true;
  }
};

function getCurrentCleanfeeds (cleanfeeds, tvchannelId, date, status, sku) {
  let data = cleanfeeds.data[`${tvchannelId}-${date}`];
  if (!data) return null;
  const filter = statusFilter(status);
  let list = data.list;
  if (status) list = list.filter(i => filter(i.status));
  if (sku !== '') list = list.filter(i => i.sku_id && i.sku_id.indexOf(sku) !== -1);
  return { ...data, list };
}

export const currentCleanfeeds = createSelector(
  state => state.cleanfeeds,
  state => state.session.tvchannelId,
  state => state.session.date,
  state => state.session.statusFilter,
  state => state.session.skuFilter,
  (cleanfeeds, tvchannelId, date, status, sku) => getCurrentCleanfeeds(cleanfeeds, tvchannelId, date, status, sku)
);

export function cleanfeed (cleanfeeds, tvchannelId, date, startDateTime) {
  let data = cleanfeeds.data[`${tvchannelId}-${date}`];
  if (!data) return null;
  return data.list.filter(i => i.start_datetime === startDateTime);
}
