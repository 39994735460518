import React from 'react';

import { Helmet } from 'react-helmet';

import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import './index.css';
import { App } from './views/App';

import configureStore from './state/store';

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

const RootHtml = () => (
  <ReduxProvider store={reduxStore}>
    <Helmet>
      <title>HSE24 Monitor</title>
    </Helmet>
    <Router>
      <App />
    </Router>
  </ReduxProvider>
);

render(<RootHtml />, document.getElementById('root'));
