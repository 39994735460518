import { combineReducers } from 'redux';
import createReducer from '../../utils/createReducer';
import * as types from './types';

const dataReducer = createReducer(null)({
  [ types.LOAD_COMPLETED ]: (state, action) => ({ ...state, data: action.payload, timestamp: Date.now() })
});

export default combineReducers({
  data: dataReducer
});
