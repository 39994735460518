import { load, retrigger, remove } from './actions';

function retriggerOperation ({ tvchannelId, startDatetime, server }) {
  return dispatch => {
    return dispatch(retrigger({ tvchannelId, startDatetime, server }));
  };
}

export {
  load,
  retriggerOperation as retrigger,
  remove
};
