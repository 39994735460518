
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const channels = ['HSE24', 'HSE24TREND', 'HSE24EXTRA', 'HSE24IT'];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  }
}));

export default function TVChannel ({ channel, onChange }) {
  const classes = useStyles();

  const [selectedChannel, setSelectedChannel] = React.useState(channel);

  function handleChannelChange (event) {
    setSelectedChannel(event.target.value);
    onChange(event.target.value);
  }

  return (
    <FormControl className={classes.root}>
      <InputLabel htmlFor='channel'>TV Channel</InputLabel>
      <Select
        value={selectedChannel}
        onChange={handleChannelChange}
        inputProps={{ name: 'channel' }}
      >
        { channels.map(ch => (
          <MenuItem key={ch} value={ch}>{ch}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
