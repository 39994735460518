import * as types from './types';

export const load = ({ tvchannelId, date }) => ({
  type: types.LOAD,
  meta: {
    tvchannelId,
    date,
    path: `/api/v1/tvchannels/${tvchannelId}/hourly-recordings?date=${date}`
  }
});

export const retrigger = ({ tvchannelId, startDatetime, server }) => ({
  type: types.RETRIGGER,
  meta: {
    path: `/api/v1/hourly-recordings`,
    method: 'post',
    data: {
      tvchannel_id: tvchannelId,
      start_datetime: startDatetime,
      server
    }
  }
});

export const remove = ({ tvchannelId, startDatetime }) => ({
  type: types.REMOVE,
  meta: {
    path: `/api/v1/tvchannels/${tvchannelId}/hourly-recording/${startDatetime}`,
    method: 'delete'
  }
});
