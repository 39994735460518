import { combineReducers } from 'redux';
import createReducer from '../../utils/createReducer';
import * as types from './types';

const dataReducer = createReducer(null)({
  [ types.LOAD_COMPLETED ]: (state, action) => action.payload,
  [ types.SAVE_COMPLETED ]: (state, action) => action.payload
});

export default combineReducers({
  data: dataReducer
});
