export const LOAD = 'privateip/LOAD';
export const LOAD_COMPLETED = 'privateip/LOAD_COMPLETED';
export const LOAD_FAILED = 'privateip/LOAD_FAILED';

export const ADD = 'privateip/ADD';
export const ADD_COMPLETED = 'privateip/ADD_COMPLETED';
export const ADD_FAILED = 'privateip/ADD_FAILED';

export const REMOVE = 'privateip/REMOVE';
export const REMOVE_COMPLETED = 'privateip/REMOVE_COMPLETED';
export const REMOVE_FAILED = 'privateip/REMOVE_FAILED';
