import * as types from './types';

export const load = () => ({
  type: types.LOAD,
  meta: {
    path: '/api/v1/configs/private_ips'
  }
});

export const add = (ip) => ({
  type: types.ADD,
  meta: {
    path: '/api/v1/configs/private-ips',
    method: 'PUT',
    data: {
      list: [ip]
    }
  }
});

export const remove = (ip) => ({
  type: types.ADD,
  meta: {
    path: '/api/v1/configs/private-ips',
    method: 'DELETE',
    data: {
      list: [ip]
    }
  }
});
