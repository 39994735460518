import * as types from './types';

export const load = (tvchannelId) => ({
  type: types.LOAD,
  meta: {
    path: `/api/v1/tvchannels/${tvchannelId}`
  }
});

export const save = (tvchannelId, data) => ({
  type: types.SAVE,
  meta: {
    path: `/api/v1/tvchannels/${tvchannelId}`,
    method: 'PUT',
    data
  }
});
