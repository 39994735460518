import { format } from 'date-fns';
import React from 'react';

import ButtonOptions from './ButtonOptions';
import ButtonAction from './ButtonAction';

import { makeStyles } from '@material-ui/core/styles';

const common = {
  display: 'inline-block',
  verticalAlign: 'middle'
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 100,
    paddingLeft: 15,
    margin: '1px 0',
    cursor: 'pointer'
  },
  time: {
    ...common,
    width: 200
  },
  image: {
    ...common,
    width: 180,
    height: '100%',
    overflow: 'hidden'
  },
  sku: {
    ...common,
    width: 100,
    textAlign: 'center'
  },
  server: {
    ...common,
    width: 100
  },
  circleA: {
    display: 'inline-block',
    padding: '10px 15px',
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 25,
    marginLeft: 15,
    backgroundColor: '#ffa'
  },
  circleB: {
    display: 'inline-block',
    padding: '10px 15px',
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 25,
    marginLeft: 15,
    backgroundColor: '#aff'
  },
  process: {
    ...common,
    width: 150,
    fontWeight: 'bold'
  },
  actions: {
    ...common,
    width: 350
  }
}));

const statusStyle = (data) => {
  let result = 'gray';
  if (data.availability === false) return 'gray';
  switch (data.status) {
    case 'succeeded':
      result = 'green';
      break;
    case 'failed':
      result = 'red';
      break;
    case 'running':
      result = 'orange';
      break;
    default:
      if (data.skipped) result = 'lightgreen';
  }
  return result;
};

export default function CleanfeedItem ({ data, onItemDetails, onRetrigger, onDelete }) {
  const classes = useStyles();
  const borderStyle = statusStyle(data);
  let server = data.server && data.server.toUpperCase();
  if (server && server.length > 1) server = server[7]; // legacy long names
  const [hover, setHover] = React.useState(false);

  return (
    <div
      className={classes.root}
      style={{ borderLeft: `5px solid ${borderStyle}`, backgroundColor: hover ? '#eee' : 'white' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onItemDetails(data)}
    >
      <div className={classes.time}>
        <h3>
          {`${format(new Date(data.start_datetime * 1000), 'H:mm:ss')} - ${(data.end_datetime && format(new Date(data.end_datetime * 1000), 'H:mm:ss')) || ''}`}
        </h3>
      </div>
      <div className={classes.image}>
        { data.urls && data.urls[0] && data.urls[0].poster && data.availability !== false &&
          <img src={data.urls[0].poster} alt='' height='100%' />
        }
        { data.availability === false &&
          <div style={{ color: 'red', margin: 40, fontWeight: 'bold' }}>DELETED</div>
        }
      </div>
      <div className={classes.sku}>
        <h3>{data.sku_id}</h3>
      </div>
      <div className={classes.server}>
        { server && <div className={server === 'A' ? classes.circleA : classes.circleB}>{server}</div> }
      </div>
      <div className={classes.process}>
        <div style={{ color: borderStyle }}>{data.status}</div>
      </div>
      <div className={classes.actions}>
        { data.status !== 'running' && ((data.status === 'failed' && data.step_function_type === 'create') || data.availability === false) &&
          <ButtonOptions
            options={['re-trigger', 're-trigger on server A', 're-trigger on server B']}
            actions={[
              onRetrigger.bind(this, data.tvchannel_id, data.start_datetime),
              onRetrigger.bind(this, data.tvchannel_id, data.start_datetime, 'a'),
              onRetrigger.bind(this, data.tvchannel_id, data.start_datetime, 'b')
            ]}
          />
        }
        { data.status === 'failed' && data.step_function_type === 'delete' &&
          <ButtonAction action={onRetrigger.bind(this, data.tvchannel_id, data.start_datetime)} />
        }
        { data.status === 'succeeded' && data.availability !== false &&
          <ButtonAction label='delete' action={onDelete.bind(this, data.tvchannel_id, data.start_datetime)} />
        }
      </div>
    </div>
  );
}
