import { format } from "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

import CachedIcon from "@material-ui/icons/Cached";

import TVChannel from "./TVChannel";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ddd",
    paddingLeft: 8,
  },
  formControl: {
    width: "100%",
  },
}));

const statuses = ["All", "Only running and failed", "Only failed"];

export default function Controller({
  channel,
  date,
  statusFilter,
  skuFilter,
  dataAsOf,
  count,
  onDateChange,
  onChannelChange,
  onStatusChange,
  onSkuChange,
  onRefresh,
}) {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date(date));
  const [selectedStatus, setSelectedStatus] = React.useState(statusFilter);
  const [selectedSku, setSelectedSku] = React.useState(skuFilter);

  function handleDateChange(date) {
    setSelectedDate(date);
    if (!isNaN(date)) onDateChange(format(date, "yyyy-MM-dd"));
  }

  function handleChannelChange(channel) {
    onChannelChange(channel);
  }

  function handleStatusChange(channel) {
    setSelectedStatus(channel.target.value);
    onStatusChange(channel.target.value);
  }

  function handleSkuChange(sku) {
    setSelectedSku(sku.target.value);
    onSkuChange(sku.target.value);
  }
  return (
    <Grid container className={classes.root} spacing={2} alignItems="center">
      <Grid item xs={2}>
        <TVChannel channel={channel} onChange={handleChannelChange} />
      </Grid>
      <Grid item xs={2}>
        <FormControl className={classes.formControl} style={{ marginTop: 8 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              format="yyyy-MM-dd"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="channel">status</InputLabel>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            inputProps={{ name: "channel" }}
          >
            {statuses.map((ch, i) => (
              <MenuItem key={ch} value={i}>
                {ch}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl className={classes.formControl}>
          <TextField
            id="standard-search"
            label="SKU"
            type="search"
            value={selectedSku}
            margin="dense"
            onChange={handleSkuChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} style={{ textAlign: "right" }}>
        {count != null && (
          <div
            style={{
              display: "inline-block",
              verticalAlign: "bottom",
              marginRight: 5,
            }}
          >
            <div style={{ textAlign: "right" }}>Items: {count}</div>
            <div style={{ fontSize: 9, fontStyle: "italic" }}>
              Data as of: {dataAsOf}
            </div>
          </div>
        )}
        {count != null && (
          <div style={{ display: "inline-block", marginBottom: -12 }}>
            <IconButton
              style={{ height: 55 }}
              color="primary"
              className={classes.refresh}
              aria-label="refresh"
              onClick={onRefresh}
            >
              <CachedIcon />
            </IconButton>
          </div>
        )}
      </Grid>
    </Grid>
  );
}
