import React from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ButtonOptions from './ButtonOptions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 10
  },
  header: {
    borderBottom: '1px solid gray',
    padding: 5
  },
  items: {
    fontSize: 12,
    padding: 10
  },
  linesHeader: {
    fontStyle: 'italic'
  },
  line: {
    height: 35,
    borderTop: '1px solid #aaa',
    cursor: 'pointer'
  },
  action: {
    margin: 0
  }
}));

export default function StatusList ({ label, list, type, onClick, onRetrigger }) {
  const classes = useStyles();
  const color = type === 'info' ? 'orange' : list.length ? 'red' : 'green';

  const [hover, setHover] = React.useState(null);

  return (
    <Paper elevation8='true' className={classes.root}>
      <div className={classes.header} style={{ backgroundColor: color }}>
        {label} {list.length ? `(${list.length})` : ''}
      </div>
      <div className={classes.items}>
        { list && list.length === 0 &&
          <div>no items</div>
        }
        { list && list.length > 0 &&
          <div>
            <Grid container className={classes.linesHeader}>
              <Grid item xs={1}>Channel</Grid>
              <Grid item xs={1}>Type</Grid>
              <Grid item xs={2}>Date</Grid>
              <Grid item xs={3}>time (from - to)</Grid>
              <Grid item xs={3}>last modified</Grid>
              <Grid item xs={2}>action</Grid>
            </Grid>
            { list.map(item =>
              <Grid
                container
                alignItems='center'
                style={{ background: hover === item.tvchannel_id + item.start_datetime ? '#ddd' : 'white' }}
                className={classes.line}
                key={item.tvchannel_id + item.start_datetime}
                onMouseEnter={() => setHover(item.tvchannel_id + item.start_datetime)}
                onMouseLeave={() => setHover(null)}
                onClick={() => onClick(item.tvchannel_id, item.start_datetime)}
              >
                <Grid item xs={1}>{item.tvchannel_id}</Grid>
                <Grid item xs={1}>{item.step_function_type}</Grid>
                <Grid item xs={2}>{format(new Date(item.start_datetime * 1000), 'yyyy-MM-dd')}</Grid>
                <Grid item xs={3}>{format(new Date(item.start_datetime * 1000), 'HH:mm:ss')} - {format(new Date(item.end_datetime * 1000), 'HH:mm:ss')}</Grid>
                <Grid item xs={3}>{item.modifiedAt}</Grid>
                <Grid item xs={2}>
                  {/* { onRetrigger &&
                    <Button
                      color='secondary'
                      size='small'
                      className={classes.action}
                      onClick={(event) => { event.stopPropagation(); onRetrigger(item.tvchannel_id, item.start_datetime); }}
                    >
                        re-trigger
                    </Button>
                  } */}
                  { onRetrigger &&
                    <ButtonOptions
                      options={['re-trigger', 're-trigger on server A', 're-trigger on server B']}
                      actions={[
                        onRetrigger.bind(this, item.tvchannel_id, item.start_datetime),
                        onRetrigger.bind(this, item.tvchannel_id, item.start_datetime, 'a'),
                        onRetrigger.bind(this, item.tvchannel_id, item.start_datetime, 'b')
                      ]}
                    />
                  }
                </Grid>
              </Grid>
            )}
          </div>
        }
      </div>
    </Paper>
  );
}
