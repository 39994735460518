export const LOAD = 'cleanfeeds/LOAD';
export const LOAD_COMPLETED = 'cleanfeeds/LOAD_COMPLETED';
export const LOAD_FAILED = 'cleanfeeds/LOAD_FAILED';

export const RETRIGGER = 'cleanfeeds/RETRIGGER';
export const RETRIGGER_COMPLETED = 'cleanfeeds/RETRIGGER_COMPLETED';
export const RETRIGGER_FAILED = 'cleanfeeds/RETRIGGER_FAILED';

export const REMOVE = 'cleanfeeds/REMOVE';
export const REMOVE_COMPLETED = 'cleanfeeds/REMOVE_COMPLETED';
export const REMOVE_FAILED = 'cleanfeeds/REMOVE_FAILED';
