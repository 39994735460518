import * as types from './types';

export const login = ({ meta }) => ({
  type: types.LOGIN,
  meta
});

export const logout = () => ({
  type: types.LOGOUT
});

export const passwordReset = ({ meta }) => ({
  type: types.PASSWORD_RESET,
  meta
});

export const passwordChange = ({ meta }) => ({
  type: types.PASSWORD_CHANGE,
  meta
});

export const init = (user) => ({
  type: types.INITIALIZE,
  payload: user
});

export const setDate = (date) => ({
  type: types.SET_DATE,
  payload: date
});

export const setTvChannel = (tvchannel) => ({
  type: types.SET_TVCHANNEL,
  payload: tvchannel
});

export const setStatusFilter = (status) => ({
  type: types.SET_STATUS_FILTER,
  payload: status
});

export const setSkuFilter = (sku) => ({
  type: types.SET_SKU_FILTER,
  payload: sku
});

export const apiStarted = (data) => ({
  type: types.API_STARTED,
  payload: data
});

export const apiSucceeded = (data) => ({
  type: types.API_SUCCEEDED,
  payload: data
});

export const apiFailed = (data) => ({
  type: types.API_FAILED,
  payload: data
});
