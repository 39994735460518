import * as types from './types';

export const load = ({ tvchannelId, date }) => ({
  type: types.LOAD,
  meta: {
    tvchannelId,
    date,
    path: `/api/v1/tvchannels/${tvchannelId}/product-presentations?date=${date}`
  }
});

export const retrigger = ({ tvchannelId, startDatetime, server }) => ({
  type: types.RETRIGGER,
  meta: {
    path: `/api/v1/tvchannels/${tvchannelId}/cleanfeeds/${startDatetime}${server ? `?server=${server}` : ''}`,
    method: 'post'
  }
});

export const remove = ({ tvchannelId, startDatetime }) => ({
  type: types.REMOVE,
  meta: {
    path: `/api/v1/tvchannels/${tvchannelId}/cleanfeed/${startDatetime}`, // TODO needs to be defined endpoint for specific cleanfeed delete
    method: 'delete'
  }
});
