import { createSelector } from 'reselect';

const statusFilter = (status) => {
  switch (status) {
    case 1:
      return (status) => status === 'running' || status === 'failed';
    case 2:
      return (status) => status === 'failed';
    default:
      return () => true;
  }
};

function exists (skus, sku) {
  return skus.reduce((p, c) => c.sku.indexOf(sku) !== -1 ? true : p, false);
}

function getCurrentHourlyRecordings (hourlyRecordings, tvchannelId, date, status, sku) {
  let data = hourlyRecordings.data[`${tvchannelId}-${date}`];
  if (!data) return null;
  const filter = statusFilter(status);
  let list = data.list;
  if (status) list = list.filter(i => filter(i.status));
  if (sku !== '') list = list.filter(i => exists(i.presentSkus, sku));
  return { ...data, list };
}

export const currentHourlyRecordings = createSelector(
  state => state.hourlyRecordings,
  state => state.session.tvchannelId,
  state => state.session.date,
  state => state.session.statusFilter,
  state => state.session.skuFilter,
  (hourlyRecordings, tvchannelId, date, status, sku) => getCurrentHourlyRecordings(hourlyRecordings, tvchannelId, date, status, sku)
);

export function hourlyRecording (hourlyRecordings, tvchannelId, date, startDateTime) {
  let data = hourlyRecordings.data[`${tvchannelId}-${date}`];
  if (!data) return null;
  return data.list.filter(i => i.start_datetime === startDateTime);
}
