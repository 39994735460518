export const LOAD = 'hourly-recordings/LOAD';
export const LOAD_COMPLETED = 'hourly-recordings/LOAD_COMPLETED';
export const LOAD_FAILED = 'hourly-recordings/LOAD_FAILED';

export const RETRIGGER = 'hourly-recordings/RETRIGGER';
export const RETRIGGER_COMPLETED = 'hourly-recordings/RETRIGGER_COMPLETED';
export const RETRIGGER_FAILED = 'hourly-recordings/RETRIGGER_FAILED';

export const REMOVE = 'hourly-recordings/REMOVE';
export const REMOVE_COMPLETED = 'hourly-recordings/REMOVE_COMPLETED';
export const REMOVE_FAILED = 'hourly-recordings/REMOVE_FAILED';
