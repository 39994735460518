import { combineReducers } from 'redux';
import createReducer from '../../utils/createReducer';
import * as types from './types';
import _ from 'lodash';

const authReducer = createReducer(false)({
  [ types.INITIALIZE ]: (state, action) => action.payload.token != null,
  [ types.LOGIN_COMPLETED ]: () => true,
  [ types.PASSWORD_CHANGE_COMPLETED ]: () => true,
  [ types.LOGOUT ]: () => false
});

const userReducer = createReducer({})({
  [ types.INITIALIZE ]: (state, action) => action.payload || {},
  [ types.LOGIN_COMPLETED ]: (state, action) => action.payload,
  [ types.PASSWORD_CHANGE_COMPLETED ]: (state, action) => action.payload,
  [ types.LOGIN_FAILED ]: (state, action) => ({ error: _.get(action.payload, 'response.data.error', action.payload) }),
  [ types.PASSWORD_CHANGE_FAILED ]: (state, action) => ({ error: _.get(action.payload, 'response.data.error', action.payload) }),
  [ types.LOGOUT ]: () => ({ token: null })
});

const tvchannelIdReducer = createReducer(null)({
  [ types.SET_TVCHANNEL ]: (state, action) => action.payload
});

const dateReducer = createReducer(new Date().toISOString().slice(0, 10))({
  [ types.SET_DATE ]: (state, action) => action.payload
});

const statusFilterReducer = createReducer(0)({
  [ types.SET_STATUS_FILTER ]: (state, action) => action.payload
});

const skuFilterReducer = createReducer('')({
  [ types.SET_SKU_FILTER ]: (state, action) => action.payload
});

const apiCallsReducer = createReducer([])({
  [ types.API_STARTED ]: (state, action) => [...state, action.payload],
  [ types.API_SUCCEEDED ]: (state, action) => state.filter(i => action.payload.id !== i.id),
  [ types.API_FAILED ]: (state, action) => state.filter(i => action.payload.id !== i.id)
});

const apiErrorsReducer = createReducer([])({
  [ types.API_FAILED ]: (state, action) => [...state, action.payload]
});

export default combineReducers({
  isAuthenticated: authReducer,
  user: userReducer,
  tvchannelId: tvchannelIdReducer,
  date: dateReducer,
  statusFilter: statusFilterReducer,
  skuFilter: skuFilterReducer,
  apiCalls: apiCallsReducer,
  apiErrors: apiErrorsReducer
});
