import React, { Component } from "react";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { Home } from "../Home";

const oktaAuth = new OktaAuth({
  issuer: "https://gl-hse24.okta.com/oauth2/default",
  clientId: "0oa5fo04o5QKQvHig417",
  redirectUri: window.location.origin + "/login/callback",
  autoRenew: true,
  scopes: ["openid", "email", "offline_access"],
});

class App extends Component {
  constructor(props) {
    super(props);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      );
    };
    oktaAuth.start();
  }

  render() {
    return (
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={this.restoreOriginalUri}
      >
        <Route path="/login/callback" component={LoginCallback} />
        <Route path="/" component={Home} />
      </Security>
    );
  }
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <Router>
        <AppWithRouterAccess />
      </Router>
    );
  }
}

export default RouterApp;
